<template>
    <AvatarUpload
        class="change-avatar"
        v-model="show"
        :width="200"
        :height="200"
        url="/api/admin/account/changeAvatar"
        img-format="png"
        @crop-upload-success="cropUploadSuccess"
    />
</template>

<script>
import AvatarUpload from "vue-image-crop-upload/upload-2.vue";

export default {
    name: "ChangeAvatar",
    components: {
        AvatarUpload
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        }
    },
    data() {
        return {};
    },
    methods: {
        cropUploadSuccess() {
            this.$store.dispatch("GetUserInfo");
        }
    }
};
</script>

<style lang="scss" scoped></style>
